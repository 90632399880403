import React, { Component } from "react";
import {
  Breadcrumb,  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Tooltip,
  OverlayTrigger
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Loader from "components/Loader/loader.jsx";
import Checkbox from "components/CustomCheckbox/CustomCheckboxv2.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { userService } from 'helpers/user.service.jsx';
const Config = require('config');


class AccountInfo extends Component {
  constructor(props) {
    super(props);

    this.vForm = this.refs.vForm;
    this.state = {
      loading: true,
      alert: null,
      show: false,
      merchant_id:null,
      merchant_name:'',
      merchant_url:'',
      merchant_email:"",
      merchant_phone:"",
      merchant_address:"",
      merchant_city:"",
      merchant_pay_fee:false,
      merchant_fee_percent:0,
      merchant_fee_eth_percent:0,
      merchant_prov_state:"",
      merchant_postal_zip:"",
      merchant_country:"",
      merchant_nameError: null,
      merchant_emailError: null,
      merchant_phoneError: null,
      merchant_addressError: null,
      merchant_cityError: null,
      merchant_prov_stateError: null,
      merchant_postal_zipError: null,
      merchant_countryError: null,
      settle_as_bitcoin:false,
      settle_as_bch:false,
      settle_as_ltc:false,
      settle_as_eth:false,
      settle_as_doge:false,
      settle_as_usdt:false,
      settle_as_usdc:false,
      settle_as_xrp:false,
      settle_as_usdt_tron:false,
      settle_as_sol:false,
      settle_as_dash:false,
      settle_as_bnb:true,
      settle_as_matic_poly:true,
      settle_as_shib:true,
      settle_as_ada:true,
      settle_as_avax:true,
      settle_as_mlp:true,
      settle_as_tsuka:true,
      Page_Error:null,
      erc20fee:0,
      ethper:0,
      merchant_pay_feeError: null,

     
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleValidate = this.handleValidate.bind(this);

 
  }

  componentWillMount(){

    this.getmerchantinfo();


  }

  getmerchantinfo(){
    userService.getinfo().then((data)=>{

      var user=JSON.parse(data);
      var auid=user.data.user_id;
    const requestOptions = {
      headers: { 'access-token': user.token ,'mid':user.data.merchant_id,'auid': auid},
    };

    fetch(Config.API+'/api/merchant',requestOptions)
    .then( this.handleResponse)
      .then( merchant => this.setState({merchant_name:merchant.data.name,merchant_url:merchant.data.url,merchant_email:merchant.data.email,merchant_phone:merchant.data.phone,merchant_city:merchant.data.city,merchant_country:merchant.data.country,merchant_postal_zip:merchant.data.postal_zip,merchant_phone:merchant.data.phone,merchant_prov_state:merchant.data.prov_state,merchant_address:merchant.data.address,merchant_id:user.data.merchant_id,merchant_fee_percent:merchant.data.client_fee_percent,merchant_fee_eth_percent:merchant.data.client_fee_eth_percent,merchant_pay_fee:merchant.data.client_pay_fee,erc20fee:merchant.erc20fee,ethper:merchant.ethper, loading: false,
        settle_as_bitcoin:merchant.data.settle_as_bitcoin,
        settle_as_bch:merchant.data.settle_as_bch,
        settle_as_eth:merchant.data.settle_as_eth,
        settle_as_doge:merchant.data.settle_as_doge,
        settle_as_ltc:merchant.data.settle_as_ltc,
        settle_as_usdt:merchant.data.settle_as_usdt,
        settle_as_usdc:merchant.data.settle_as_usdc,
        settle_as_xrp:merchant.data.settle_as_xrp,
        settle_as_usdt_tron:merchant.data.settle_as_usdt_tron,
        settle_as_sol:merchant.data.settle_as_sol,
        settle_as_dash:merchant.data.settle_as_dash,
        settle_as_bnb:merchant.data.settle_as_bnb,
        settle_as_mlp:merchant.data.settle_as_mlp,
        settle_as_tsuka:merchant.data.settle_as_tsuka,
        settle_as_matic_poly:merchant.data.settle_as_matic_poly,
        settle_as_avax:merchant.data.settle_as_avax,
        settle_as_ada:merchant.data.settle_as_ada,
        settle_as_shib:merchant.data.settle_as_shib,
        merchant_fee_eth_percent:merchant.data.client_fee_eth_percent},function(){

          if(this.state.merchant_pay_fee){document.getElementById('1').checked=true}else{document.getElementById('1').checked=false}
  if(this.state.merchant_pay_fee){
          if(this.state.settle_as_bitcoin){document.getElementById('2').checked=true}else{document.getElementById('2').checked=false}
          if(this.state.settle_as_bch){document.getElementById('3').checked=true}else{document.getElementById('3').checked=false}
          if(this.state.settle_as_ltc){document.getElementById('4').checked=true}else{document.getElementById('4').checked=false}
          if(this.state.settle_as_eth){document.getElementById('5').checked=true}else{document.getElementById('5').checked=false}
          if(this.state.settle_as_doge){document.getElementById('6').checked=true}else{document.getElementById('6').checked=false}
          if(this.state.settle_as_usdt){document.getElementById('7').checked=true}else{document.getElementById('7').checked=false}
          if(this.state.settle_as_usdc){document.getElementById('8').checked=true}else{document.getElementById('8').checked=false}
          if(this.state.settle_as_xrp){document.getElementById('9').checked=true}else{document.getElementById('9').checked=false}
          if(this.state.settle_as_usdt_tron){document.getElementById('10').checked=true}else{document.getElementById('10').checked=false}
          if(this.state.settle_as_sol){document.getElementById('11').checked=true}else{document.getElementById('11').checked=false}
          if(this.state.settle_as_dash){document.getElementById('12').checked=true}else{document.getElementById('12').checked=false}

          if(this.state.settle_as_avax){document.getElementById('13').checked=true}else{document.getElementById('13').checked=false}

          if(this.state.settle_as_ada){document.getElementById('14').checked=true}else{document.getElementById('14').checked=false}

          if(this.state.settle_as_shib){document.getElementById('15').checked=true}else{document.getElementById('15').checked=false}

          if(this.state.settle_as_matic_poly){document.getElementById('16').checked=true}else{document.getElementById('16').checked=false}
     
  }
  
        }));

     
      })
    }


    updatemerchantinfo(name,address,city,prov_state,postal_zip,email, country,phone,url,merchant_pay_fee,settle_as_bitcoin,settle_as_bch,settle_as_ltc,settle_as_eth,settle_as_doge,settle_as_usdt,settle_as_usdc,settle_as_xrp,settle_as_sol,settle_as_dash,settle_as_usdt_tron,settle_as_bnb,settle_as_avax,settle_as_ada,settle_as_matic_poly,settle_as_shib,settle_as_mlp,settle_as_tsuka){
      userService.getinfo().then((data)=>{

        var user=JSON.parse(data);
      var mid=user.data.merchant_id;
      var auid=user.data.user_id;
var token=user.token;
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' ,'access-token': token,'mid':user.data.merchant_id,'auid': auid},
         body: JSON.stringify({ name,address,city,prov_state,postal_zip,email,country,phone,url,merchant_pay_fee,settle_as_bitcoin,settle_as_bch,settle_as_ltc,settle_as_eth,settle_as_doge,settle_as_usdt,settle_as_usdc,settle_as_xrp,settle_as_sol,settle_as_dash,settle_as_usdt_tron,settle_as_bnb,settle_as_avax,settle_as_ada,settle_as_matic_poly,settle_as_shib,settle_as_mlp,settle_as_tsuka})
      };
          
    
  return fetch(Config.API+`/api/merchant/update`,requestOptions)
     .then( this.handleResponse)
     .then(this.props.handleClick("tr","success","Account info has been updated",<span data-notify='icon' className='pe-7s-check' />))
    
    })
  }


  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{
        //  console.log(data.status)
          if(data.status=='error'){
            userService.logout()
            
               return 'error'
          }
        

        }
       

        return data;
    });
}


    handleChange(e) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
   
  }



  
  handleValidate(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    re.test(this.state.merchant_email) === false
      ? this.setState({
        merchant_emailError: (
            <small className="text-danger">
              This must be a valid email address.
            </small>
          )
        })
      : this.setState({ merchant_emailError: null });
    
      this.state.merchant_address === ""
      ? this.setState({
        merchant_addressError: (
            <small className="text-danger">field is  required.</small>
          )
            })
      : this.setState({ merchant_addressError: null });

        this.state.merchant_phone=== ""
        ? this.setState({
          merchant_phoneError: (
              <small className="text-danger">a valid phone number is required.</small>
            )
              })
        : this.setState({ merchant_phoneError: null });

      this.state.merchant_name === ""
      ? this.setState({
        merchant_nameError: (
            <small className="text-danger">field is  required.</small>
          )
           })
      : this.setState({ merchant_nameError: null });
     
      this.state.merchant_prov_state === ""
      ? this.setState({
        merchant_prov_stateError: (
            <small className="text-danger">field is  required.</small>
          )
           })
      : this.setState({ merchant_prov_stateError: null });
      
  
      this.state.merchant_postal_zip === ""
      ? this.setState({
        merchant_postal_zipError: (
            <small className="text-danger">field is  required.</small>
          )
           })
      : this.setState({ merchant_postal_zipError: null });
    
      this.state.merchant_country === ""
      ? this.setState({
        merchant_countryError: (
            <small className="text-danger">field is  required.</small>
          )
           })
      : this.setState({ merchant_countryError: null });
    
      this.state.merchant_city === ""
      ? this.setState({
        merchant_cityError: (
            <small className="text-danger">field is  required.</small>
          )
          })
      : this.setState({ merchant_cityError: null });


      document.getElementById('1').checked!==true
      ? this.setState({merchant_pay_fee:false,settle_as_bitcoin: false,settle_as_bch: false,settle_as_ltc: false,settle_as_eth: false,settle_as_doge: false,settle_as_usdt: false,settle_as_usdc: false,settle_as_xrp: false,settle_as_usdt_tron: false,settle_as_dash: false,settle_as_sol: false,settle_as_avax: false,settle_as_ada: false,settle_as_matic_poly: false,settle_as_shib: false})
      : this.setState({merchant_pay_fee:true})

      if(this.state.merchant_pay_fee){
      document.getElementById('2').checked!==true
      ? this.setState({settle_as_bitcoin: false })
      : this.setState({settle_as_bitcoin: true})



      document.getElementById('3').checked!==true
      ? this.setState({settle_as_bch: false })
      : this.setState({settle_as_bch: true})


      document.getElementById('4').checked!==true
      ? this.setState({settle_as_ltc: false })
      : this.setState({settle_as_ltc: true})


      document.getElementById('5').checked!==true
      ? this.setState({settle_as_eth: false })
      : this.setState({settle_as_eth: true})

      document.getElementById('6').checked!==true
      ? this.setState({settle_as_doge: false })
      : this.setState({settle_as_doge: true})

      document.getElementById('7').checked!==true
      ? this.setState({settle_as_usdt: false })
      : this.setState({settle_as_usdt: true})

      document.getElementById('8').checked!==true
      ? this.setState({settle_as_usdc: false })
      : this.setState({settle_as_usdc: true})

      document.getElementById('9').checked!==true
      ? this.setState({settle_as_xrp: false })
      : this.setState({settle_as_xrp: true})



      document.getElementById('10').checked!==true
      ? this.setState({settle_as_usdt_tron: false })
      : this.setState({settle_as_usdt_tron: true})



      document.getElementById('11').checked!==true
      ? this.setState({settle_as_sol: false })
      : this.setState({settle_as_sol: true})



      document.getElementById('12').checked!==true
      ? this.setState({settle_as_dash: false })
      : this.setState({settle_as_dash: true})


      document.getElementById('13').checked!==true
      ? this.setState({settle_as_avax: false })
      : this.setState({settle_as_avax: true})


      document.getElementById('14').checked!==true
      ? this.setState({settle_as_ada: false })
      : this.setState({settle_as_ada: true})


      document.getElementById('15').checked!==true
      ? this.setState({settle_as_shib: false })
      : this.setState({settle_as_shib: true})


      document.getElementById('16').checked!==true
      ? this.setState({settle_as_matic_poly: false })
      : this.setState({settle_as_matic_poly: true})


      }
  
  }

  handleSubmit(e) {
    e.preventDefault();
    const { merchant_name,merchant_address,merchant_city,merchant_prov_state,merchant_postal_zip,merchant_email,merchant_country,merchant_phone,merchant_url,merchant_pay_fee,settle_as_bitcoin,settle_as_bch,settle_as_ltc,settle_as_eth,settle_as_doge,settle_as_usdt,settle_as_usdc,settle_as_xrp,settle_as_sol,settle_as_dash,settle_as_usdt_tron,settle_as_bnb,settle_as_avax,settle_as_ada,settle_as_matic_poly,settle_as_shib,settle_as_mlp,settle_as_tsuka} = this.state;
   if( this.state.merchant_cityError === null && this.state.merchant_nameError  === null && this.state.merchant_addressError  === null && this.state.merchant_prov_stateError  === null && this.state.merchant_postal_zipError  === null && this.state.merchant_countryError  === null && this.state.merchant_emailError  === null && this.state.merchant_phoneError  === null){
  this.updatemerchantinfo(merchant_name,merchant_address,merchant_city,merchant_prov_state,merchant_postal_zip,merchant_email,merchant_country,merchant_phone,merchant_url,merchant_pay_fee,settle_as_bitcoin,settle_as_bch,settle_as_ltc,settle_as_eth,settle_as_doge,settle_as_usdt,settle_as_usdc,settle_as_xrp,settle_as_sol,settle_as_dash,settle_as_usdt_tron,settle_as_bnb,settle_as_avax,settle_as_ada,settle_as_matic_poly,settle_as_shib,settle_as_mlp,settle_as_tsuka)}

   }




  render() {
    const { merchant_fee_percent,merchant_fee_eth_percent,merchant_pay_fee,merchant_name,merchant_email,merchant_address,merchant_city,merchant_prov_state,merchant_postal_zip, merchant_country, merchant_phone, merchant_id,loading,merchant_url,erc20fee,ethper,settle_as_bitcoin,settle_as_bch,settle_as_ltc,settle_as_eth,settle_as_doge,settle_as_usdt,settle_as_usdc,settle_as_xrp,settle_as_sol,settle_as_dash,settle_as_usdt_tron,settle_as_avax,settle_as_ada,settle_as_matic_poly,settle_as_shib,settle_as_mlp,settle_as_tsuka} = this.state;
    if (loading) return <Loader />
     return (
      <div className="main-content">


 <Grid fluid>
   
          <Row>
        
        <Breadcrumb>
            <Breadcrumb.Item href="/#/settings">Settings</Breadcrumb.Item>
            <Breadcrumb.Item active>Account Info</Breadcrumb.Item>
          </Breadcrumb>
                    <Col md={12}>  <h3>Account Info</h3>
              <Form horizontal onSubmit={this.handleSubmit}>
                <Card
            
                  content={
                    <div>
                            <Col lg={12}>   </Col> 
 <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Merchant ID
                        </Col>
                        <Col sm={6}>
                       
                           <div  className="textdisplay">{merchant_id}</div>
                        </Col>
                      </FormGroup>





                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         Name
                        </Col>
                        <Col sm={6}>
                          {merchant_name}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Address
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="merchant_address"
                          value={merchant_address}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.merchant_addressError}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        City
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="merchant_city"
                          value={merchant_city}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.merchant_cityError}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                       Province/State
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="merchant_prov_state"
                          value={merchant_prov_state}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.merchant_prov_stateError}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                     Country
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="merchant_country"
                          value={merchant_country}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.merchant_countryError}
                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Postal/Zip Code
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="merchant_postal_zip"
                          value={merchant_postal_zip}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.merchant_postal_zipError}
                        </Col>
                      </FormGroup>



                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                       Phone Number
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="merchant_phone"
                          value={merchant_phone}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.merchant_phoneError}
                        </Col>
                      </FormGroup>




                      <FormGroup controlId="formHorizontalEmail"> 
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                          Email
                        </Col>
                        <Col sm={6}> 
                          <FormControl
                            type="email"
                            value={merchant_email}
                            name="merchant_email"
                         onBlur={this.handleValidate}
                         onChange={this.handleChange}
                          />
             { this.state.merchant_emailError}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalEmail"> 
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                          Website Url
                        </Col>
                        <Col sm={6}> 
                          <FormControl
                            type="text"
                            value={merchant_url}
                            name="merchant_url"
                         onBlur={this.handleValidate}
                         onChange={this.handleChange}
                          />
            
                        </Col>
                      </FormGroup>
                         
                   
   <FormGroup controlId="formHorizontalEmail"> 
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Merchant Pay Fee <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
     If checked off the merchant will pay the fees, otherwise the end user will pay the fees. NOTE: To settle in crypto the merchant MUST pay the fees. <br></br>For USDT and USDC the merchant will always pay the fees.
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={6}> 
                        <Checkbox
                        isChecked={merchant_pay_fee}
                        id="1"
                        onBlur={this.handleValidate}
                        onChange={this.handleValidate}
                      />
                 
             { this.state.merchant_pay_feeError}
                        </Col>
                      </FormGroup>

                      { merchant_pay_fee==true
? <div>
                      <FormGroup controlId="formHorizontalEmail"> 
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         Settle as BTC <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
     If checked off payments made with BTC will settle as BTC not as the requested fiat currency (USD,CAD,EUR,GBP)
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={6}> 
                        <Checkbox
                        isChecked={settle_as_bitcoin}
                        id="2"
                        onBlur={this.handleValidate}
                        onChange={this.handleValidate}
                      />

             { this.state.settle_as_bitcoinError}
                        </Col>
                      </FormGroup>



                      <FormGroup controlId="formHorizontalEmail"> 
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         Settle as LTC <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
     If checked off payments made with LTC will settle as LTC not as the requested fiat currency (USD,CAD,EUR,GBP)
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={6}> 
                        <Checkbox
                        isChecked={settle_as_ltc}
                        id="4"
                        onBlur={this.handleValidate}
                        onChange={this.handleValidate}
                      />

             { this.state.settle_as_ltcError}
                        </Col>
                    </FormGroup>


                      <FormGroup controlId="formHorizontalEmail"> 
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         Settle as BCH <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
     If checked off payments made with BCH will settle as BCH not as the requested fiat currency (USD,CAD,EUR,GBP)
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={6}> 
                        <Checkbox
                        isChecked={settle_as_bch}
                        id="3"
                        onBlur={this.handleValidate}
                        onChange={this.handleValidate}
                      />

             
                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalEmail"> 
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         Settle as ETH <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
     If checked off payments made with ETH will settle as ETH not as the requested fiat currency (USD,CAD,EUR,GBP)
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={6}> 
                        <Checkbox
                        isChecked={settle_as_eth}
                        id="5"
                       
                      />

          
                        </Col>
                      </FormGroup>

               <FormGroup controlId="formHorizontalEmail"> 
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         Settle as DOGE <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
     If checked off payments made with DOGE will settle as DOGE not as the requested fiat currency (USD,CAD,EUR,GBP)
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={6}> 
                        <Checkbox
                        isChecked={settle_as_doge}
                        id="6"
                       
                      />

        
                        </Col>
                      </FormGroup>
                      <FormGroup controlId="formHorizontalEmail"> 
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         Settle as XRP <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
     If checked off payments made with XRP will settle as XRP not as the requested fiat currency (USD,CAD,EUR,GBP)
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={6}> 
                        <Checkbox
                        isChecked={settle_as_xrp}
                        id="9"
                       
                      />

            
                        </Col>
                      </FormGroup>
                      <FormGroup controlId="formHorizontalEmail"> 
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         Settle as USDT  <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
     If checked off payments made with USDT will settle as USDT not as the requested fiat currency (USD,CAD,EUR,GBP)
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={6}> 
                        <Checkbox
                        isChecked={settle_as_usdt}
                        id="7"
                       
                      />

                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalEmail"> 
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         Settle as USDC <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
     If checked off payments made with USDC will settle as USDC not as the requested fiat currency (USD,CAD,EUR,GBP)
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={6}> 
                        <Checkbox
                        isChecked={settle_as_usdc}
                        id="8"
                       
                      />

      
                        </Col>
                      </FormGroup>



                      <FormGroup controlId="formHorizontalEmail"> 
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         Settle as USDT (TRON) <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
     If checked off payments made with USDT (TRON) will settle as USDT (TRON) not as the requested fiat currency (USD,CAD,EUR,GBP)
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={6}> 
                        <Checkbox
                        isChecked={settle_as_usdt_tron}
                        id="10"
                       
                      />


                        </Col>
                      </FormGroup>



                      <FormGroup controlId="formHorizontalEmail"> 
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         Settle as SOL <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
     If checked off payments made with SOL will settle as SOL not as the requested fiat currency (USD,CAD,EUR,GBP)
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={6}> 
                        <Checkbox
                        isChecked={settle_as_sol}
                        id="11"
                       
                      />

     
                        </Col>
                      </FormGroup>



                      <FormGroup controlId="formHorizontalEmail"> 
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         Settle as DASH <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
     If checked off payments made with DASH will settle as DASH not as the requested fiat currency (USD,CAD,EUR,GBP)
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={6}> 
                        <Checkbox
                        isChecked={settle_as_dash}
                        id="12"
                       
                      />


                        </Col>
                      </FormGroup>
                    
                      <FormGroup controlId="formHorizontalEmail"> 
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         Settle as BNB
                        </Col>
                        <Col sm={6}> 
                        (Only available as crypto to crypto)
                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalEmail"> 
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         Settle as AVAX <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
     If checked off payments made with AVAX will settle as AVAX not as the requested fiat currency (USD,CAD,EUR,GBP)
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={6}> 
                        <Checkbox
                        isChecked={settle_as_avax}
                        id="13"
                       
                      />

          
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalEmail"> 
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         Settle as ADA <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
     If checked off payments made with ADA will settle as ADA not as the requested fiat currency (USD,CAD,EUR,GBP)
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={6}> 
                        <Checkbox
                        isChecked={settle_as_ada}
                        id="14"
                       
                      />

   
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalEmail"> 
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         Settle as SHIB (ERC20) <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
     If checked off payments made with SHIB (ERC20) will settle as SHIB (ERC20) not as the requested fiat currency (USD,CAD,EUR,GBP)
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={6}> 
                        <Checkbox
                        isChecked={settle_as_shib}
                        id="15"
                       
                      />

      
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalEmail"> 
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         Settle as POLYGON (MATIC) <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
     If checked off payments made with POLYGON (MATIC) will settle as POLYGON (MATIC) not as the requested fiat currency (USD,CAD,EUR,GBP)
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={6}> 
                        <Checkbox
                        isChecked={settle_as_matic_poly}
                        id="16"
                       
                      />

          
                        </Col>
                      </FormGroup>



                      <FormGroup controlId="formHorizontalEmail"> 
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         Settle as MLP (ERC20)
                        </Col>
                        <Col sm={6}> 
                        (Only available as crypto to crypto)
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalEmail"> 
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         Settle as TSUKA (ERC20)
                        </Col>
                        <Col sm={6}> 
                        (Only available as crypto to crypto)
                        </Col>
                      </FormGroup>


                      </div>
                    :''}
                    </div>
                  }
      
                  legend={
                    <Button
                      fill
                      bsStyle="info"
                      type="submit"
                      onClick={this.handleValidate}
                    >
                      Save
                    </Button>
                  }
                />
              </Form>
            </Col>

      <Col lg={12}>  <h3>Fees</h3> </Col> 
            <Col md={12}> 
              <Form horizontal >
                <Card
            
                  content={
                    <div>
                      
                        
                        <FormGroup >     <Col  sm={2} smOffset={2}>
                        Fees
                        </Col>
                        <Col sm={6}>
                        <div  className="textdisplay">{merchant_fee_percent*100}%</div>
                        </Col></FormGroup>
                        <FormGroup >   <Col  sm={2} smOffset={2}>
                        ETH Fees
                        </Col>
                        <Col sm={6}>
                        <div  className="textdisplay">{merchant_fee_eth_percent*100}%</div>
                         

                
                        </Col>
                        </FormGroup>
                        <FormGroup >   <Col  sm={2} smOffset={2}>
                        ERC20 Token Fees 
                        </Col>
                        <Col sm={6}>
                        <div  className="textdisplay">{merchant_fee_percent*100}% + ${erc20fee}</div>
                         

                
                        </Col>
                        </FormGroup>
                     
                         
                   
                    
                    </div>
                  }
      
               
                />
              </Form>
            </Col>


           
                 
                  </Row>
               
        </Grid>
      </div>
    );
  }
}

export default AccountInfo ;
