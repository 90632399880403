let Config = {
API:'https://payapi.newagecrypto.com:5000',
//API:'http://localhost:5000',
  MinInvoice:25,
  MaxInvoice:2000000,
  gtag:'AW--000000',
  Company:'NACE Pay',
  Website:'https://newagecrypto.com',
  WidgetUrl:'https://apps.newagecrypto.com/widget/nacepay.js?v4',
  WidgetName:'nacepay',
  VerifyUrl:'https://newagecrypto.com/merchant_verify/',
  POSUrl:'',
  SignupUrl:'/#/a/register-page',
  wordpress:'',
  ShowSignup:false,
  InvoiceUrl:"https://apps.newagecrypto.com/payinvoice/",
  fees:{wire:15.00,erc20:10.00,trc20:3.00}
};
module.exports = Config