import React, { Component } from "react";
import { Grid,  Row,  Col } from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import { userService } from 'helpers/user.service.jsx';
const Config = require('config');




class Billing extends Component {


  constructor(props) {
    super(props);

    this.vForm = this.refs.vForm;
    this.state = {
      kyc:false
     
    };

 
  
 
  }

  componentWillMount(){

    userService.getinfo().then((data)=>{

      var user=JSON.parse(data);

    if (user.data.kyc=='Verified'){this.setState({kyc:true})}else{
this.setState({kyc:false})}
    })
  }

  render() {
    const {kyc} = this.state; 
 
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
          <Col lg={12}>  <h3>Billing</h3> </Col>
          <Col md={12}>
          <Card
             
                
                content={
                  <Row>
                    <Col md={5}>
                    <div className="table-responsive">
                  {kyc==true?<a href="/#/billing/invoices">  Invoices</a>:<div>Invoices (You must verify your account) <br/><a href={Config.VerifyUrl} target="_blank">Verify Account</a></div>} <br/>
View, Edit and Add Invoices
                      </div>
                    </Col>
                    <Col md={5}>
                      <div className="table-responsive">
                      {kyc==true?<a href="/#/billing/customers">Customers</a>:<div>Customers (You must verify your account) <br/><a href={Config.VerifyUrl} target="_blank">Verify Account</a></div>}<br/>
                       View, Edit and Add Customer
                      </div>
                    </Col>
               
               
                  </Row>
                }
              /></Col>
   
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Billing;
