import React, { Component } from "react";
import {
  Grid,
  Row,
  Col
} from "react-bootstrap";
import Cookies from 'universal-cookie';
import Login from "components/Login/Login.jsx";
import { userService } from 'helpers/user.service.jsx';


class LoginPage extends Component {
  constructor(props) {
    super(props);
    userService.logout();
    this.state = {
      cardHidden: true,
      username: '',
      password: '',
      submitted: false,
      loading: false,
      error: '',
      verifyerror: '',
      twofa_verify:false,
      userdata:'',
      verifycode:'',
      verifycodeError:'',
      mid:'',
      verifydisable:false,
      verifydisplay:false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleVerifySubmit = this.handleVerifySubmit.bind(this)
    this.handleValidate= this.handleValidate.bind(this)
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
}

handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password } = this.state;

    // stop here if form is invalid
    if (!(username && password)) {
        return;
    }

    this.setState({ loading: true });
    userService.login(username, password)
    .then(
      twofa_enabled => { this.setState({ twofa_verify:twofa_enabled,verifydisplay:true},function(){})
        
       
        }).catch((error)=>{this.setState({ error, loading: false })})
      }

      handleVerifySubmit(e) {
        e.preventDefault();
        userService.login2faverify(this.state.username,this.state.password,this.state.verifycode).then((userdata)=>{
         
      if(userdata.status=='success'){
      
      
        const { from } = this.props.location.state || { from: { pathname: "/" } };
        this.props.history.push(from);
      }else{
        this.setState({verifyerror:userdata.message})
      }
      
      
        }).catch(()=>{
          this.setState({verifyerror:'Verification code is invalid, please try again.'})
        })
      
      }
      
      
      cancel(){
      
        userService.logout()
        this.setState({     username: '',
        password: '',
        submitted: false,
        loading: false,
        loading2fa: false,
        error: '',
        verifyerror: '',
        twofa_verify:false,
        uid:'',
        token:'',
        mid:'',
        verifycode:'',
        verifycodeError:'',
        mid:'',
        verifydisable:false,
        verifydisplay:false})
      }
      
      handleValidate(){
      
      
        this.state.verifycode.length<= 5
        ? this.setState({
          verifycodeError: (
        <small className="text-danger"></small>
        ),verifydisable:true
        })
            : isNaN(this.state.verifycode)
            ?  this.setState({
              verifycodeError: (
              <small className="text-danger">Code must be numeric</small>
              ),verifydisable:true
              })
            : this.setState({ verifycodeError: null ,verifydisable:false});
      
      }
  componentDidMount() {
    const cookies = new Cookies();
    cookies.remove('auth',{ path: '/' })
    cookies.remove('uid',{ path: '/' })
    localStorage.removeItem('assets');
    setTimeout(
      function() {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
  }
  render() {
    const { username, password, submitted, loading, error,twofa_verify,verifycode, verifycodeError,verifyerror,loading2fa,verifydisable,verifydisplay } = this.state;
    return (
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
        
          {verifydisplay==false
             ? <Login
                hidden={this.state.cardHidden}
                textCenter
                content={    <form name="form" onSubmit={this.handleSubmit}>
                  <div>
                  <h2>Sign in to your account</h2>
                  <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                  <label htmlFor="username">Email</label>
                  <input type="email" className="form-control" name="username" value={username} onChange={this.handleChange} />
                  {submitted && !username &&
                      <div className="help-block">Email is required</div>
                  }
              </div>
              <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                  <label htmlFor="password">Password</label> 
                  <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                  {submitted && !password &&
                      <div className="help-block">Password is required</div>
                  }
              </div>
              <div className="form-group">
                  <button className="btn btn-primary" disabled={loading}>Sign in</button>
                  {loading &&
                      <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  }
              </div>
              {error &&
                  <div className={'alert alert-danger'}>{error}</div>
              }

<span className="forgotPass"><a href="/#/a/forgot-password">Forgot your password?</a></span>
                  </div>  </form>
                }
              
                ftTextCenter
              /> 
              : twofa_verify==false
              ?<Login
              hidden={this.state.cardHidden}
              textCenter
              content={    <form name="form" onSubmit={this.handleVerifySubmit}>
                <div>
                <h2>Enter the verification code sent to your email address</h2>
                <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                <label htmlFor="verifycode">verification code</label>
                <input type="text" className="form-control" name="verifycode" value={verifycode} onChange={this.handleChange} 
                
                onBlur={this.handleValidate}/>
            <div>{verifycodeError}</div> 
          </div>
          <div className="form-group">
        <button className="btn btn-primary" disabled={verifydisable}>Verify</button>
                {loading2fa &&
                    <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                }
            </div>

               {verifyerror &&
                <div className={'alert alert-danger'}>{verifyerror}</div>
            }
          <div className="cancel2fa" onClick={()=>this.cancel()}>Cancel</div>
            </div>
                </form>
         }    ftTextCenter
         />
        : <Login
        hidden={this.state.cardHidden}
        textCenter
        content={    <form name="form" onSubmit={this.handleVerifySubmit}>
          <div>
          <h2>Enter your 2FA verification code from your app</h2>
          <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
          <label htmlFor="verifycode">verification code</label>
          <input type="text" className="form-control" name="verifycode" value={verifycode} onChange={this.handleChange} 
          
          onBlur={this.handleValidate}/>
      <div>{verifycodeError}</div> 
    </div>
    <div className="form-group">
  <button className="btn btn-primary" disabled={verifydisable}>Verify</button>
          {loading2fa &&
              <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          }
      </div>

         {verifyerror &&
          <div className={'alert alert-danger'}>{verifyerror}</div>
      }
    <div className="cancel2fa" onClick={()=>this.cancel()}>Cancel</div>
      </div>
          </form>
   }    ftTextCenter
   /> }
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default LoginPage;
