import React, { Component } from "react";
import {
  Breadcrumb,  Grid,
  Row,
  Col,
  Form, 
  Modal,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";



import Card from "components/Card/Card.jsx";
import Loader from "components/Loader/loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { userService } from 'helpers/user.service.jsx';
const Config = require('config');
var md5 = require('md5');

class Profile extends Component {
  constructor(props) {
    super(props);
   
    this.vForm = this.refs.vForm;
    this.state = { showModal: false,
      error:false,
      loading: true,
      alert: null,
      show: false,
      username:'',
      firstname:"",
      lastname:"",
      old_passwordError:null,
      new_passwordError:null,
      new_password2Error:null,
      old_password:"",
      new_password:"",
      new_password2:"",
      update:false

    
     
    };


 
  }

  componentWillMount(){

    this.getuserinfo();


  }

  getuserinfo(){
    userService.getinfo().then((data)=>{

      var user=JSON.parse(data);

      var auid=user.data.user_id;
    const requestOptions = {
      headers: { 'access-token': user.token,'mid':user.data.merchant_id ,'auid': auid},
    };

    fetch(Config.API+'/api/user/'+user.data.user_id,requestOptions)
    .then( this.handleResponse)
      .then( user => this.setState({username:user.data.username,firstname:user.data.firstname,lastname:user.data.lastname,loading: false}));

     
  })
    }



    handleChange(e) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
   
  }





  




  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{

          if(data.status=='error'){
            userService.logout()
            
               return 'error'

          }
        
        

        }
       

        return data;
    });
}


  render() {
    const { old_password,new_password,new_password2} = this.state;
    if (this.state.loading) return <Loader />
      return (
      <div className="main-content">
                      
        <Grid fluid>
       
          <Row>
         
       
                    <Col md={12}> 
              <Form horizontal >
                <Card
                  title="User Profile"
                  content={
                    <div>
              


              <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         User Name
                        </Col>
                        <Col  sm={6}>
                        <div className="textdisplay" >{this.state.username}</div>
                        </Col>
                      </FormGroup>


        <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         First Name
                        </Col>
                        <Col sm={6}>
                        <div className="textdisplay" >{this.state.firstname}</div>
                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Last Name
                        </Col>
                        <Col  sm={6}>
                        <div className="textdisplay" > {this.state.lastname}</div>
                        </Col>
                      </FormGroup>

                     
                   
                    
                    </div>
                  }
      
                />
              </Form>
            </Col>
                 
                  </Row>
               
        </Grid>
      </div>
    );
  }
}

export default Profile ;
